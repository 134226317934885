import type { Cart } from '@containex/portal-backend-api-client';

// eslint-disable-next-line complexity
export function hasSameShippingAddress(addr1: Cart['shipping_address'], addr2: Cart['shipping_address']): boolean {
    return (
        addr1?.id === addr2?.id &&
        addr1?.address_1 === addr2?.address_1 &&
        addr1?.address_2 === addr2?.address_2 &&
        addr1?.city === addr2?.city &&
        addr1?.country_code === addr2?.country_code &&
        addr1?.first_name === addr2?.first_name &&
        addr1?.last_name === addr2?.last_name &&
        addr1?.phone === addr2?.phone &&
        addr1?.province === addr2?.province &&
        addr1?.postal_code === addr2?.postal_code &&
        addr1?.customer_id === addr2?.customer_id
    );
}

export function hasSameLineItemsAndQuantity(lineItems1: Cart['items'], lineItems2: Cart['items']): boolean {
    if (lineItems1.length !== lineItems2.length) {
        return false;
    }

    // check if there's at least one inconsistency
    return !lineItems1.some((item) => {
        const foundItem = lineItems2.find((li) => li.id === item.id);

        if (foundItem != null && foundItem.quantity === item.quantity) {
            return false;
        }

        return true;
    });
}

export function hasSameLineItemGroups(
    lineItemGroups1: Cart['lineItemGroups'],
    lineItemGroups2: Cart['lineItemGroups']
): boolean {
    if (lineItemGroups1.length !== lineItemGroups2.length) {
        return false;
    }

    // check if there's at least one inconsistency
    return !lineItemGroups1.some((lineItemGroup) => {
        const foundLineItemGroup = lineItemGroups2.find((lig) => lig.id === lineItemGroup.id);

        if (foundLineItemGroup == null) {
            return true;
        }

        if (
            foundLineItemGroup.depot.depot_id === lineItemGroup.depot.depot_id &&
            foundLineItemGroup.estimated_delivery_date_start === lineItemGroup.estimated_delivery_date_start &&
            foundLineItemGroup.estimated_delivery_date_end === lineItemGroup.estimated_delivery_date_end &&
            foundLineItemGroup.transport_type === lineItemGroup.transport_type &&
            foundLineItemGroup.delivery_time === lineItemGroup.delivery_time
        ) {
            return false;
        }
        return true;
    });
}
