<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import Dialog from 'primevue/dialog';
    import Button from 'primevue/button';

    defineProps<{
        isVisible: boolean;
    }>();

    const emits = defineEmits<{
        close: [];
        confirm: [];
    }>();

    const { t } = useI18n();
</script>

<template>
    <Dialog
        :visible="isVisible"
        modal
        :header="t('CHECKOUT.INCONSISTENT_CART_DIALOG.HEADER')"
        :style="{ width: '40rem', 'max-width': '100%' }"
        :pt="{ closeButton: { onClick: () => emits('close') } }"
        @hide="emits('close')"
    >
        <div class="content">
            <div class="description">
                {{ t('CHECKOUT.INCONSISTENT_CART_DIALOG.DESCRIPTION') }}
            </div>
            <div class="actions">
                <Button
                    :label="t('CHECKOUT.INCONSISTENT_CART_DIALOG.CANCEL')"
                    type="button"
                    outlined
                    autofocus
                    @click="emits('close')"
                />
                <Button
                    :label="t('CHECKOUT.INCONSISTENT_CART_DIALOG.CONFIRM')"
                    type="button"
                    @click="emits('confirm')"
                />
            </div>
        </div>
    </Dialog>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .actions {
        display: flex;
        justify-content: end;
        gap: 1ch;
    }

    .content {
        padding-bottom: main.$spacing-6;
    }

    .description {
        padding-bottom: main.$spacing-6;
    }
</style>
