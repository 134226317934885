import { format } from 'date-fns';
import { calculateRentalDays } from '@containex/portal-business-logic';

export function getRentalDuration(rentalStart: Date | null | undefined, rentalEnd: Date | null | undefined): number {
    if (rentalStart == null || rentalEnd == null) {
        return 0;
    }
    const rentalStartIso = new Date(format(rentalStart, 'yyyy-MM-dd'));
    return calculateRentalDays(rentalStartIso, rentalEnd);
}
