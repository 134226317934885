import { defineStore } from 'pinia';
import { computed, ref, type ComputedRef } from 'vue';
import { type Cart } from '@containex/portal-backend-api-client';

interface CheckoutCartStoreState {
    checkoutCart: ComputedRef<Cart | null>;
    setCheckoutCart(newCheckoutCart: Cart): void;
    resetCheckoutCart(): void;
}
export const useCheckoutCartStore = defineStore<'checkoutCartStore', CheckoutCartStoreState>(
    'checkoutCartStore',
    () => {
        const checkoutCart = ref<Cart | null>(null);

        return {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            checkoutCart: computed(() => checkoutCart.value as Cart | null),
            setCheckoutCart(newCheckoutCart: Cart): void {
                checkoutCart.value = newCheckoutCart;
            },
            resetCheckoutCart(): void {
                checkoutCart.value = null;
            },
        };
    }
);
